<script>
/**
 * Top-users component
 */
import axios from 'axios';
export default {
    mounted() {
        this.getData()
    },
    data() {
        return {
            tickets: [],
            users: [{
                profile: require("@/assets/images/users/avatar-4.jpg"),
                name: "Glenn Holden",
                location: "Nevada",
                status: "Cancel",
                price: "$250.00",
            },
            {
                profile: require("@/assets/images/users/avatar-5.jpg"),
                name: "Lolita Hamill",
                location: "Texas",
                status: "Success",
                price: "$110.00",
            },
            {
                profile: require("@/assets/images/users/avatar-6.jpg"),
                name: "Robert Mercer",
                location: "California",
                status: "Active",
                price: "$420.00",
            },
            {
                profile: require("@/assets/images/users/avatar-7.jpg"),
                name: "Marie Kim",
                location: "Montana",
                status: "Pending",
                price: "$120.00",
            },
            {
                profile: require("@/assets/images/users/avatar-8.jpg"),
                name: "Sonya Henshaw",
                location: "Colorado",
                status: "Active",
                price: "$112.00",
            },
            {
                profile: require("@/assets/images/users/avatar-2.jpg"),
                name: "Marie Kim",
                location: "Australia",
                status: "Success",
                price: "$120.00",
            },
            {
                profile: require("@/assets/images/users/avatar-1.jpg"),
                name: "Sonya Henshaw",
                location: "India",
                status: "Cancel",
                price: "$112.00",
            },
            ],
        };
    },
    methods: {
        async getData() {
            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket')
                .then(response => {
                    console.log('ticket', response)
                    this.tickets = response.data
                })
        }
    }
};
</script>

<template>
    <div class="col-xl-4">
        <div class="card">
            <div class="card-body" style="min-height: 506px; max-height: 516px;">
                
                <h5 variant="primary" class="mb-3">Ticket List</h5>
               
            
                <div data-simplebar style="max-height: 406px;">
                    
                    <div class="table-responsive">
                        <table class="table table-borderless table-centered table-nowrap" >
                            <tbody>
                                <tr v-for="(item, index) in tickets" :key="index">
                                    <!-- <td style="width: 20px;">
                                    <img :src="item.profile" class="avatar-xs rounded-circle" alt="..." />
                                </td> -->
                                    <td>
                                        <h6 class="font-size-15 mb-1 fw-semibold">{{ item.customerName }} </h6>
                                        <p class="text-muted font-size-13 mb-0">
                                            <i class="uil-ticket"></i>
                                            {{ item.noTicket }}
                                        </p>
                                    </td>
                                    <td>
                                        <!-- <span class="badge font-size-12" :class="{
                                        'bg-soft-success': item.status === 'Success',
                                        'bg-soft-danger': item.status === 'Cancel',
                                        'bg-soft-info': item.status === 'Active',
                                        'bg-soft-warning': item.status === 'Pending'}">{{item.status}}</span> -->
                                        <span v-if="item.stage == 'ticketSubmitted'"
                                            class="badge font-size-12 bg-soft-danger">Ticket Dibuat</span>
                                        <span v-else-if="item.stage == 'ticketOnProgress'"
                                            class="badge font-size-12 bg-soft-danger">Dalam Proses</span>
                                        <span v-else-if="item.stage == 'ticketProgressDone'"
                                            class="badge font-size-12 bg-soft-info">Pengerjaan Selesai</span>
                                        <span v-else-if="item.stage == 'ticketDone'"
                                            class="badge font-size-12 bg-soft-success">Ticket Selesai</span>
                                    </td>
                                    <td class="text-muted fw-semibold text-end">

                                        {{ item.createdAt }}
                                        <p v-if="item.assignment" class="text-muted font-size-11 mb-0">
                                            <i class="far fa-user"></i>
                                            {{ item.assignment }}
                                        </p>
                                        <p v-else class="text-muted font-size-13 mb-0">
                                            <i class="far fa-user"></i>
                                            -
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- enbd table-responsive-->
                </div>
                <!-- data-sidebar-->
            </div>
            <!-- end card-body-->
        </div>
        <!-- end card-->
    </div>
<!-- end col --></template>
