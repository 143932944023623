<script>
/**
 * Activity component
 */

import axios from 'axios';
import { TimeAgo } from "vue2-timeago";
import "vue2-timeago/dist/vue2-timeago.css";

//const moment = require('moment');
//let users = JSON.parse(localStorage.getItem('users'))

export default {

    components:
    {
        TimeAgo,
    },

    data() {
        return {
            listAktifitas: [],
            timeholder: {
                betweenTime: []
            },
            tooltip: true,

            dropdownValue: 'Terbaru',
            dropdownText: 'Terbaru',

            dropDownList: [
                { value: 'Terbaru', text: 'Terbaru' },
                { value: 'Register', text: 'Registrasi' },
                { value: 'BillPayment', text: 'Pembayaran' },

            ],






        }

    },

    watch: {

    },



    async mounted() {
        await this.getRecent()
    },



    methods: {

        async getRecent() {

            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'recent-activity' + "?$sort[createdAt]=-1&$limit=20").then(response => {
                var test
                for (let index = 0; index < response.data.data.length; index++) {

                    test = {
                        'user': response.data.data[index].user,
                        '_id': response.data.data[index]._id,
                        'massage': response.data.data[index].message,
                        'refRoute': response.data.data[index].refRoute,
                        'createdAt': response.data.data[index].createdAt,
                        'updatedAt': response.data.data[index].updatedAt,
                        'activityType': response.data.data[index].activityType,
                        'handleBy': response.data.data[index].handleBy
                    }
                    this.listAktifitas.push(test)

                }
              
            });
        },


    },



};




</script>

<template>
    <div class="col-xl-4">
        <div class="card">
            
            <div class="card-body">
                <div class="float-end">
                    <b-dropdown right toggle-class="p-0" variant="white" menu-class="dropdown-menu-end">
                        <template v-slot:button-content>
                            <span class="text-muted">
                                {{ dropdownText }}
                                <i class="mdi mdi-chevron-down ms-1"></i>
                            </span>
                        </template>


                        <b-dropdown-item v-for="items in dropDownList" :key="items.value"
                            @click="dropdownValue = items.value, dropdownText = items.text"
                            v-show="items.value != 'Terbaru' || dropdownValue != 'Terbaru'"> {{ items.text
                            }}</b-dropdown-item>

                    </b-dropdown>
                </div>

                <h4 class="card-title mb-4">Akitifitas Terbaru</h4>



                <ol class="activity-feed mb-0 ps-1" data-simplebar style="min-height: 406px; max-height: 421px;">
                    <div class="table">
                        <li class="feed-item pb-3" v-for="recent in listAktifitas" :key="recent._id"
                            v-show="recent.activityType == dropdownValue || dropdownValue == '' || dropdownValue == 'Terbaru'">

                            <div class="feed-item-list">
                                <div class="timeago" style ="padding: 0.15rem 0.75rem">
                                    <time-ago :datetime="recent.updatedAt" refresh tooltip="tooltip" long locale="id">
                                    </time-ago>
                                </div>
                                <div class="mt-0 mb-0 pt-0" v-if = "recent.activityType == 'BillPayment'">
                                    {{ recent.handleBy }} {{ recent.massage }}

                                    <span class="text-primary">
                                        <p class="mb-0">
                                            <a :href=recent.refRoute>
                                             {{recent.user}}
                                            </a>
                                        </p>
                                    </span>
                                </div>
                                <div class="mt-0 mb-0 pt-0" v-if = "recent.activityType == 'Register'">
                                    {{ recent.handleBy }} {{ recent.massage }}
                                    <span class="text-primary">
                                        <p class="mb-0">
                                            <a :href=recent.refRoute>
                                                {{recent.user}}
                                            </a>
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </div>
                </ol>
            </div>
        </div>
    </div>
</template>

   
